import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import './slide.css'

import Swiper from 'react-id-swiper'
const Slide = () => {
  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  }

  return (
    <Layout head={{ title: 'Testimonials' }}>
      <CommonHero
        pageTitle="Testimonials"
        custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1556763394/brianboals/citybg.jpg"
      />
      <Swiper {...params}>
        <div>Slide 1</div>
        <div>Slide 2</div>
        <div>Slide 3</div>
        <div>Slide 4</div>
        <div>Slide 5</div>
      </Swiper>
    </Layout>
  )
}

export default Slide
